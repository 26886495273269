/* Font raleway */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap");

:root {
  --primary-color: #080808; /* Vampire Black */
  --secondary-color: #121212; /* Chinese Black */
  --nav-bar-color-light: #f2f2f2; /* Anti-Flash White */
  --nav-bar-color-dark: #d2d2d2; /* Light Gray */
  --body-color: #bdbdbd; /* X11 Gray */
  --section-header-color: #aaaaaa; /* X11 Dark Gray */
  --section-content-color: #0072bb; /* French Blue */
  --section-border-color: #1e91d6; /* Cyan Cornflower Blue */
}

/* Navbar */
.navbar-custom {
  background-color: var(--nav-bar-color-light);
  padding-top: 0px;
  padding-bottom: 0px;
}

.logo-img {
  max-width: 60px;
  max-height: 60px;
}

.custom-nav-link {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.custom-nav-link::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 10%;
  background-color: var(--picton-blue);
  opacity: 0;
  transition: all 0.3s ease;
}

.custom-nav-link:hover::before {
  font-weight: bold;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.custom-nav-link:hover {
  color: var(--section-content-color);
  font-weight: bold;
}

body {
  font-family: "Raleway", "Arial", sans-serif;
  background-color: var(--primary-color);
  color: #333;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.container {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  /* padding: 20px; */
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

/* Header */
header {
  background-color: var(--body-color);
  height: 100px;
  width: 100%;
  position: relative;
}

header img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

/* Sections */
section {
  width: 100%;
  margin-top: 100px;
}

section img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
}

section p {
  text-align: center;
}

/* Buttons */
button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/* Footer */

footer {
  background-color: #f9f9f9;
  height: 50px;
  width: 100%;
  position: relative;
}

footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 12px;
}

footer li {
  display: inline-block;
  margin-right: 20px;
  padding-bottom: 20px;
}

footer a {
  text-decoration: none;
  font-size: 16px;
}

footer a:hover {
  text-decoration: none;
}

footer .social-media {
  float: right;
}

footer .social-media a {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  text-decoration: none;
}

footer .social-media a:hover {
  background-color: #555;
}

footer .call-to-action {
  text-align: center;
}

footer .call-to-action a {
  background-color: #f44336;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

footer .call-to-action a:hover {
  background-color: #d32f2f;
}

/* Fin footer */

/* Navbar */
.navbar-custom {
  background-color: var(--nav-bar-color-light);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-img {
  max-width: 60px;
  max-height: 60px;
}

.navbar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  margin-left: 10px;
}

.navbar-links a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: var(--section-content-color);
}
