.textCondiciones {
  border: none;
  outline: none;
  resize: none;
  text-align: center;
}

.condiciones {
  padding-left: 6vw;
  padding-right: 6vw;
}

.condicionesIni {
  padding-top: 9vw;
  padding-left: 8vw;
  padding-right: 8vw;
  text-align: justify;
}

.textTit {
  font-weight: bold;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.titEncuestaCliente {
  text-align: center;
  font-size: 3vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.preguntasEncCli {
  padding-left: 10vw;
  padding-right: 10vw;
}

.dFlexEnc {
  display: flex;
  justify-content: space-around;
}

.niveles {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pregLabel {
  display: flex;
  background-color: #77adb2;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px;
}

.pregLabel .number {
  background-color: #1e597c;
  border-radius: 8px;
  padding: 6px;
  color: #ffffff;
}

.pregunta {
  padding-left: 1vw;
}

.pbot-2 {
  padding-bottom: 2vw;
}

.lvl5 {
  color: #7e93f1;
}

.lvl4 {
  color: #1e597c;
}
.lvl3 {
  color: #3282f6;
}
.lvl2 {
  color: #79c680;
}
.lvl1 {
  color: #2c7f87;
}
.nivelResp {
  font-weight: 501;
}
.feedback-button {
  min-width: 15vw;
}

.custom-select {
  /* background-color: #d9d9d9; */
  border-radius: 0;
  width: 12vw;
}

.padSelect {
  padding-top: 1vw;
  padding-left: 6vw;
  padding-bottom: 1vw;
}

.nivelRespSub {
  font-weight: 50;
  font-size: 1.5vw;
}

.colorRed {
  color: red;
}

select.colorRed option:checked {
  color: red;
}

select.custom-select option.colorVerde {
  background-color: #63cc7a;
}

select.custom-select option.colorRojo {
  background-color: #dc3545;
}

select.custom-select option.colorAmarillo {
  background-color: #ffc107;
}
select.custom-select option.colorDefault {
  background-color: #ffffff;
}
