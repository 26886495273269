.textCondiciones {
  border: none;
  outline: none;
  resize: none;
  text-align: center;
}

.condiciones {
  padding-left: 6vw;
  padding-right: 6vw;
}

.datosClienteTit {
  padding-top: 6vw;
  text-align: center;
  color: #0f50b3;
  font-weight: 501;
  font-size: 3vw;
}

.datosCliente {
  padding-top: 6vw;
  padding-left: 35vw;
  padding-right: 8vw;
  text-align: left;
}

.textTit {
  font-weight: bold;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.sName {
  text-align: center;
}

.sNameInput {
  width: 34vw;
  height: 3vw;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 20px;
  background: linear-gradient(to right, #DEFEE6, #BBD3FE);
}

.subtitle {
  text-align: center;
  font-weight: 501;
  padding-top: 1vw;
}

.divButton {
  text-align: right;
  padding-right: 25vw;
  padding-top: 4vw;
}
