@font-face {
  font-family: "Exo 2";
  src: url("fonts/Exo2-Medium.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

html,
body {
  height: 100%;
  width: 100%;

  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
  font-weight: 900;
}

.ffExo {
  font-family: "Exo 2", sans-serif;
}

.hcard {
  height: 80%;
}

.section {
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0;
}

.section-1 {
  background: linear-gradient(to right, #9e77f3, rgb(15, 80, 179));
}

/* Seccion 1 */

.section-1 {
  position: relative;
  z-index: 1;
  height: 35vw;
  display: flex;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-1-left {
  flex: 1;
  position: relative;
  z-index: 0;
}

.zInd-2 {
  z-index: 2;
}

.section-1-right {
  flex: 1;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.3vw;
  font-weight: 200;
}

.section-1-right .centered-text {
  width: 100%;
  text-align: center;
}

.section-1-right {
  align-self: center;
  margin-top: 20px;
}

.learn-more-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffffff;
  color: rgb(15, 80, 179);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Fin seccion 1 */

/* Seccion 2 */

.section-2 {
  display: flex;
  align-items: center;
  height: 35vw;
}

.text-p8 {
  margin-left: 8vw;
  margin-right: 8vw;
}

.section-2-left {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 2.3vw;
  font-weight: 200;
  /* padding-bottom: 1rem; */
}

.section-2-right {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.3vw;
  font-weight: 200;
  background-image: url("../img/arquitectos.png");
  background-size: cover;
  background-position: right;
  min-height: 100vh;
  filter: grayscale(100%);
}

.quien_somos_text {
  text-align: left;
  word-wrap: break-word;
  font-size: 4vw;
  color: rgb(15, 80, 179);
  font-size: 5vw;
}

/* Fin seccion 2 */

.section-3 {
  background-color: #ffffff; /* Azul */
}

/* Seccion 3 */

.section-3 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section-top {
  flex: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(15, 80, 179);
}

.section-bottom {
  flex: 80;
  display: flex;
  flex-wrap: wrap; /* Nueva propiedad para que los elementos se ajusten en diferentes tamaños de pantalla */
  justify-content: center; /* Añadimos alineación central */
  align-items: center; /* Añadimos alineación central */
}

.bottom-left,
.bottom-middle,
.bottom-right {
  flex-basis: 33.33%; /* Cambiamos la propiedad para que los elementos se ajusten correctamente */
  display: flex;
  justify-content: center;
  align-items: center;
}



.content-wrapper {
  border: 2px dotted #0f50b3; /* Estilo de borde punteado personalizable */
  padding: 20px; /* Espacio interior personalizable */
  margin: 4rem; /* Cambiamos el margen para que se ajuste en diferentes tamaños de pantalla */
  border-radius: 10px; /* Radio de borde personalizable */
  text-align: center; /* Centramos el contenido del contenedor */
  text-decoration: none;
}

.content-wrapper:hover {
  /* Estilos al pasar el cursor sobre el contenedor */
  background-color: #e7e8f0; /* Cambiamos el color de fondo ligeramente */
  cursor: pointer;
}

.top {
  flex: 40;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle {
  flex: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bottom {
  flex: 50;
  display: flex;
  justify-content: center;
}

.bottom-middle {
  background-color: #0000ff; /* Ejemplo: Azul */
}

.bottom-right {
  background-color: #75753e; /* Ejemplo: Amarillo */
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 50%;
  height: 50%;
  object-fit: contain;
}

.num_imagen {
  color: rgb(0, 196, 204);
}

.tit_tarjeta {
  font-size: 2vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0f50b3;
}
.text_tarjeta {
  font-size: 1vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Fin seccion 3 */

/* Seccion 4 */

.section-4 {
  background: linear-gradient(to right, #9e77f3, rgb(15, 80, 179));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 35vw;
}

.background-image {
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #9e77f3, rgb(15, 80, 179));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: black;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.imgSect4 {
  display: flex;
  justify-content: center;
  align-content: center;
}

.slogan {
  font-size: 2rem;
}

/* Fin seccion 4 */

/* Seccion 5 */
.section-5 {
  background: linear-gradient(to right, #9e77f3, rgb(15, 80, 179));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.section-content {
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.social_net {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 2vw; /* Ajusta el tamaño de fuente según tus preferencias */
  padding-left: 2rem;
}

.centered-text {
  margin-bottom: 10px;
}

.contacto-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffffff;
  color: rgb(15, 80, 179);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.centered-text {
  text-align: center;
}

.jContEnd {
  justify-content: end;
}

/* Fin seccion 5 */

.link-sin-subrayado {
  text-decoration: none;
  color: black;
}

.link-sin-subrayado:hover {
  text-decoration: none;
  color: black;
}
