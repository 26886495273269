.textCondiciones {
  border: none;
  outline: none;
  resize: none;
  text-align: center;
}

.condiciones {
  padding-left: 6vw;
  padding-right: 6vw;
}

.condicionesIni {
  padding-top: 9vw;
  padding-left: 8vw;
  padding-right: 8vw;
  text-align: justify;
}

.textTit {
  font-weight: bold;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.titEncuestaCliente {
  text-align: center;
  font-size: 3vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.preguntasEncCli {
  padding-left: 10vw;
  padding-right: 10vw;
}

.dFlexEnc {
  display: flex;
  justify-content: space-around;
}

.niveles {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pregLabel {
  display: flex;
  background-color: #77adb2;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px;
}

.pregLabel .number {
  background-color: #1e597c;
  border-radius: 8px;
  padding: 6px;
  color: #ffffff;
}

.pregunta {
  padding-left: 1vw;
}

.pbot-2 {
  padding-bottom: 2vw;
}

.lvl5 {
  color: #7e93f1;
}

.lvl4 {
  color: #1e597c;
}
.lvl3 {
  color: #3282f6;
}
.lvl2 {
  color: #79c680;
}
.lvl1 {
  color: #2c7f87;
}
.nivelResp {
  font-weight: 501;
}
.feedback-button {
  min-width: 15vw;
}

.custom-select {
  /* background-color: #d9d9d9; */
  border-radius: 0;
  width: 12vw;
}

.padSelect {
  padding-top: 1vw;
  padding-left: 6vw;
  padding-bottom: 1vw;
}

.nivelRespSub {
  font-weight: 50;
  font-size: 1.5vw;
}

.colorRed {
  color: red;
}

select.colorRed option:checked {
  color: red;
}

select.custom-select option.colorVerde {
  background-color: #63cc7a;
}

select.custom-select option.colorRojo {
  background-color: #dc3545;
}

select.custom-select option.colorAmarillo {
  background-color: #ffc107;
}

.backgroundRed {
  background-color: red;
}

/* Cambios para los proyectos */

.container {
  display: flex;
  align-items: center;
}

.column {
  flex: 1;
  margin-right: 10px; /* Ajusta el margen entre las columnas según tus necesidades */
}
.column2 {
  flex: 0.1;
  margin-right: 10px; /* Ajusta el margen entre las columnas según tus necesidades */
}

.tooltipCust {
  display: flex;
  align-items: center;
  position: relative;
}

.tooltipCust .tooltiptext {
  visibility: hidden;
  width: 8vw;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%; /* Cambiado a 100% para que aparezca a la derecha */
  transform: translateY(-50%); /* Ajuste vertical del tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipCust:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  font-size: 0.8vw;
}

.inputText {
  width: 100%;
}

.alignRigth {
  text-align: end;
}

.padTop2 {
  padding-top: 2vw;
}

.img-small {
  display: inline-block;
  width: 3vw; /* ajusta el tamaño según tus necesidades */
  height: 3vw; /* ajusta el tamaño según tus necesidades */
  object-fit: contain;
}

.pt-1r {
  padding-top: 1rem;
}
